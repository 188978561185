<template>
  <v-hover v-slot="{hover}" >
    <v-sheet
        :color="hover || edit || editDropDownOn ? wsLIGHTCARD : ''"
        style="position : relative"
        ref="mainElement"
        min-height="60"
        class="d-flex wsRoundedLight align-center justify-space-between transition-ease-in-out" >

      <v-sheet color="transparent" class="d-flex align-center"  >
        <v-icon
            @click="expandAction"
            :class="value ? 'rotate' : ''"
            class="pointer"
            :color="wsACCENT">
          mdi-menu-right
        </v-icon>
        <h5 v-if="!edit"
              style="outline: none; font-size: 14px; font-weight: 700 !important ; resize: none "
              :style="!topic.name ? 'color : grey' : ''"
          >
            {{ topic.name || $t('EnterTopicName') }}
            <v-fade-transition>
              <v-icon
                  @click="startEdit"
                  v-ripple v-if="hover || edit || editDropDownOn"
                  :color="wsACCENT"
                  size="20"
              >
                mdi-pencil
              </v-icon>
            </v-fade-transition>
          </h5>
        <textarea
            v-else
            v-model="topic.name"
            @change="editTopic"
            @keydown.enter="editTopic"
            @blur="edit=false"
            :readonly="!edit"
            ref="text"
            v-autowidth="{maxWidth: '680px', minWidth: '1px', comfortZone: 0}"
            v-autogrow
            class="readOnlyCursor text-decoration-underline px-0"
            type="text"
            :placeholder="$t('EnterTopicName')"
            style="outline: none; font-size: 14px; font-weight: 700 !important ; resize: none "
            rows="1"
        />


      </v-sheet>
      <v-sheet v-if="hover || edit || editDropDownOn"  color="transparent" class="d-flex">
        <ws-icon-button v-if="topic.order_number > 1" @click="$emit('reorder' , -1)" icon="mdi-chevron-up"  class=""   />
        <ws-icon-button v-if="topic.order_number < topicsLength" @click="$emit('reorder' , 1)" icon="mdi-chevron-down"  class=" "   />
        <ft-select
            @input="editAction"
            @expand="editDropDownOn = $event"
            :items="getEditIcons('topic')"
            left-icons
            left >

          <v-sheet width="35" color="transparent" class="pr-2">
            <ws-icon-button  class="mr-4"  />
          </v-sheet>

        </ft-select>
      </v-sheet>

      <!-- Add Item Button-->
      <div v-if="(hover || edit || editDropDownOn) && (!expand || topic.components.length === 0)"
           style="z-index:999; position: absolute; bottom : -30px ; right: 0; left: 0 ; margin-left : auto; margin-right: auto">

        <div style="position: relative">
          <v-divider  style="border-style: dashed ;" ></v-divider>
          <div class="d-flex justify-center">
            <ft-select
                @input="addItem($event)"
                @expand="editDropDownOn = $event"
                :items="getAddItems('module')"
                :min-height="$store.state.courses.course.online ? '340' : '300'"
                :icon-color="wsATTENTION"
                colorBlack>

              <v-btn large style="margin-top: -26px"
                     icon :color="wsATTENTION"><v-icon large>mdi-plus-circle</v-icon> </v-btn>
            </ft-select>
          </div>
        </div>

      </div>

    </v-sheet>
  </v-hover>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "courseEditorTopic",
  props : {
    value : {
      type : Boolean,
      default : true,
    },
    topic : {
      type : Object,
      default() { return {} }
    },
    index : {
      type : Number,
    },
    moduleIndex : {
      type : Number,
    },
    topicsLength : {
      type : Number,
    },
    newCourse : {
      type : Boolean,
      default : false,
    }
  },
  data() {
    return {
      expand : true,
      edit : false,
      editDropDownOn : false,
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),
  },
  watch : {
    value () {
      if ( this.value !== this.expand ) {
        this.expand = this.value
      }
    },
    expand () {
      if ( this.value !== this.expand ) {
        this.$emit('expand', this.expand )
      }
    },
    topic(value) {
      if ( value !== this.topic ) {
        this.prepareExpand()
      }
    }
  },
  methods : {

    ...mapActions('courses', [
        'ADD_MODULE',
        'ADD_TOPIC',
        'EDIT_TOPIC',
        'DELETE_TOPIC',
        'ADD_COMPONENT'
    ]),

    addItem(type) {

      if ( type === 'module') {
        this.addModule()
      } else if ( type === 'topic') {
        this.addTopic()
      } else {
        if ( !this.expand ) {
          this.expandAction()
        }
        this.addComponent(type)
      }
    },
    editAction(action) {
      if ( action === 'delete' ) {
        this.deleteTopic()
      }
    },

    async editTopic() {
      this.edit = false
      let entityData = {
        uuid : this.topic.uuid,
        name : {
          [this.selectedLang] : this.topic.name
        }
      }

      let data = await this.EDIT_TOPIC(entityData)

      if (!data) {
        return this.notify(this.$t('Error'))
      }

    },
    async deleteTopic() {

      if ( !await this.DELETE_TOPIC(this.topic.uuid) ) {
        return this.notify(this.$t('EntityCannotBeDeleted'))
      }

      let data = {
        entity : 'topic' ,
        topicIndex : this.index ,
        moduleIndex : this.moduleIndex
      }

      this.$emit('delete' , data )
      this.notify(this.$t('TopicDeleted'))

    },

    async addModule() {

      let data = {
        course_id : this.topic.course_id,
        lang : this.selectedLang,
        block : this.topic.module_block + 1
      }

      let result = await this.ADD_MODULE( data )
      if ( !result ) {
        return
      }

      this.$emit('update' , { entity : 'module' , data : result } )

    },
    async addTopic() {

      let data = {
        module_id : this.topic.module_id,
        lang : this.selectedLang,
        order_number : this.topic.order_number + 1
      }

      let result = await this.ADD_TOPIC(data)

      if ( !result ) {
        return
      }
      this.$emit('update' , { entity : 'topic' , data : result } )

    },
    async addComponent(type) {

      let data = {
        entity_type : type,
        lang : this.selectedLang,
        topic_id : this.topic.uuid
      }

      let result = await this.ADD_COMPONENT(data)

      data = {
        entity : 'component' ,
        data : result,
        topicIndex : this.index ,
        moduleIndex : this.moduleIndex,
      }

      this.$emit('update' , data )

    },

    // technical
    startEdit() {

      this.edit = true
      this.$nextTick(() => {
        let text = this.$refs.text
        let end = text.value.length || 1
        text.setSelectionRange(end,end)
        text.focus()
      })
    },
    expandAction() {
      this.expand = !this.expand
    },
    getAddItems() {
      let items = [
        { text : this.$t('Module') , value: 'module' , icon : 'mdi-book-variant' },
        { text : this.$t('Topic')  , value: 'topic'  , icon : 'mdi-book-variant' },
        { text : this.$t('Lecture')      , value : 'lecture'    , icon : 'mdi-book-open'              },
        { text : this.$t('Assignment')   , value : 'task'       , icon : 'mdi-content-paste'          },
        { text : this.$t('Test')         , value : 'test'       , icon : 'mdi-order-bool-ascending-variant' },
        { text : this.$t('VideoMeeting') , value : 'task_video' , icon : 'mdi-cast-education'         },
        { text : this.$t('Video_')        , value : 'video'      , icon : 'mdi-video'                  },
        { text : this.$t('File')         , value : 'file'       , icon : 'mdi-file'                   },
      ];
      if ( !this.$store.state.courses.course.online ) {
        items.splice(5,1)
      }
      return items
    },
    getEditIcons() {
      return [
        // { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ]
    },
    prepareExpand() {
      if (this.topic.expand === undefined || this.topic.expand === null ) {
        this.topic.expand = true
        this.topic = Object.assign({},this.topic)
      }
    },
    getTextStyle() {
      let style = this.edit ? 'cursor : text;' : ' cursor : pointer;';

      if (!this.topic.name) {
        style += `color : ${this.wsDARKLIGHT}`
      }

      return style
    },
    textClicked() {
      if ( !this.edit ) {
        this.expandAction()
      }
    }

  },
  mounted() {
    this.prepareExpand()
  }
}
</script>

<style scoped>


.rotate {
  transform: rotate(90deg);
}

.text[placeholder]:empty:before {
  content: attr(placeholder);
}

[contenteditable=true]:empty:not(:focus):before{
  content:attr(data-ph);
}
</style>