<template>
  <ws-dialog
      v-model="display"
      :title="$t('testing.create_question')"
      @save="handleSave"
      :save-text="$t('Add')"
      :disable-save="!entityData.uuid"
      :width="entityData.uuid ? 800 : 600"

  >
    <!-- Choose question type -->
    <div v-if="!entityData.uuid">
      <h4 class="wsACCENT font-weight-regular mb-5">{{ $t('testing.choose_question_type') }}:</h4>
      <v-sheet style="border : 1px solid var(--wsBACKGROUND)" class="wsRoundedSemi">
        <v-hover v-for="(item,i) in QUESTION_TYPES_SELECT" :key="i" #default="{hover}">
          <div
              @click="selectType(item)"
               class="pa-3 px-4 pointer d-flex align-center justify-space-between"
               :style="getStyle(i,hover)"
               v-ripple
          >
            <div class="d-flex align-center">
              <v-icon class="mr-3" :color="wsATTENTION">{{ item.icon || 'mdi-cog' }}</v-icon>
              <h5 class="wsDARKER">{{ item.text }}</h5>
            </div>

            <v-icon :color="wsACCENT">mdi-chevron-right</v-icon>


          </div>
        </v-hover>

      </v-sheet>
    </div>

    <div v-else>
      <question-editor
          v-if="entityData.uuid"
          :question="entityData"
          :index="0"
          single-question
      />



    </div>

    <!-- CONTENT-->

  </ws-dialog>
</template>

<script>
import tests from "@/mixins/tests";
import {mapActions, mapState} from "vuex";
import questionEditor from "@/components/pages/westudy/admin/course/questionBanks/questionEditor.vue";

export default {
  name: "testQuestionsCreator",
  components : {
    questionEditor
  },
  mixins : [tests],
  props : {
    value : {
      type : Boolean
    },
    component : {
      type : Object,
      default() { return {}}
    }
  },
  data() {
    return {
      questionType : null,
      entityData : {},
      display : false,
      timeout : false,
      emitedOnce : false
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang']),
  },
  methods : {
    ...mapActions('tests', [
      'ADD_BANK_QUESTION_FROM_TEST'
    ]),

    handleSave() {
      if (this.emitedOnce) {
        return
      }
      this.emitedOnce = true
      this.$emit('save' , [this.entityData.uuid])
    },
    async selectType(item) {

      if (this.entityData.uuid) {
        return
      }

      this.questionType = item.value

      let data = {
        type : item.value,
        component_id : this.component.uuid,
        component_name : this.component.name,
        lang : this.selectedLang
      }

      let result = await this.ADD_BANK_QUESTION_FROM_TEST(data)
      if (!result) {
        return this.ERROR()
      }
      result.expand = true

      this.entityData = this.COPY(result)

    },
    //technical
    getStyle(i , hover) {
      let style = ''
      if (hover) {
        style += `background : ${this.wsLIGHTCARD};`
      }
      if (i > 0) {
        style += `border-top : 1px solid ${this.wsBACKGROUND};`
      }
      return style
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>