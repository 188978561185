<template>
  <v-hover v-slot="{hover}">
      <div class="pt-4" v-if="section.isFile" >

        <v-row  no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

          <!-- Drag Icon-->
          <v-col style=" height: 100% !important;" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <v-sheet color="transparent" width="24" class="lecture-section-drag pt-1">
              <v-icon v-if="hover"  style="cursor: grab"  :color="wsACCENT">
                mdi-drag-vertical
              </v-icon>
            </v-sheet>
          </v-col>
          <!-- Section Content -->
          <v-col style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">

            <!-- File Uploader -->
            <div v-if="section.files.length > 0">
              <div   v-for="(file,i) in section.files"  :key="'file_' + i">
                <ft-select @input="fileAction($event,file, i )" absolute :items="editFileSelect">
                  <v-btn
                      :color="wsACCENT"
                      class="noCaps px-0 pr-2"
                      text>
                    <v-icon class="mr-3" :color="wsACCENT">mdi-paperclip</v-icon>
                    {{ file.name }}
                  </v-btn>
                </ft-select>
              </div>
            </div>
            <!-- File Uploader -->
            <div v-else class="py-4" style="width: 100%">
              <ws-file-uploader :lang="lang" @success="addFile"   />
            </div>




          </v-col>
          <!-- Section Edit Buttons -->
          <v-col style=" height: 100% !important;" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <v-sheet width="32" color="transparent" class="pl-1 pt-2 fill-height " >
              <ft-select
                  @input="$emit('edit',$event)"
                  :items="editActionsSelect">
                <ws-icon-button v-if="hover" small   />
              </ft-select>
            </v-sheet>
          </v-col>

        </v-row>

      </div>
  </v-hover>

</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "lectureSectionFile",
  props : {
    value : {
      type : Object
    },
    section : {
      type : Object,
      default() { return { files : [] } }
    },
    lang : {
      type : String,
      default : 'ua',
    },
    componentId : {
      type : String,
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),

    editFileSelect() {
      return [
        { text : this.$t('Open')   , value: 'open' , icon : 'mdi-folder-open-outline' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ];
    },
    editActionsSelect() {
      return [
        { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ];
    },
  },
  methods : {
    ...mapActions('courses',['EDIT_COMPONENT']),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    fileAction( action, file , index ) {
      if ( action === 'open' ) {
        this.openFile(file)
      }
      if ( action === 'delete'  ) {
        this.section.files.splice(index,1)
        this.$emit('input',this.section)
      }

    },
    async openFile(file) {
      let result = await this.GET_PRIVATE_FILE(file.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    async addFile($event) {
      this.section.files.push($event)
      let result = await this.addComponentFile($event)
      if ( !result ) {
        return
      }
      this.$emit('input', this.section)
    },
    async addComponentFile(file) {
      let entityData = {
        uuid : this.componentId,
        lang : this.selectedLang,
        file : file.uuid,
        editor_content : true,
      }

      let result = await this.EDIT_COMPONENT(entityData)
      if (!result) {
         this.notify(this.$t('Error'))
        return false
      }
      return true
    },
  },
  mounted() {
    if (this.value) {
      this.section = this.value
    }
  }
}
</script>

<style scoped>

</style>