<template>
<div>
  <!-- Passing Score -->
  <div class="px-10 d-flex align-center justify-space-between"
       style="border-bottom: 1px solid var(--wsBACKGROUND)"
  >
    <h5 class="font-weight-regular wsDARKER">
      {{ $t('PassingScore') }}
    </h5>

    <ws-text-field
        v-model="test.passing_score"
        @change="editTest($event,'passing_score')"
        class="py-2"
        placeholder="50"
        number
        number-length="3"
        width="111"
        hide-details
    />
  </div>

  <!-- Display Results -->
  <div class="px-10 d-flex align-center justify-space-between"
       style="border-bottom: 1px solid var(--wsBACKGROUND)"
  >
    <h5 class="font-weight-regular wsDARKER d-flex align-center mr-5">
      {{ $t('TestDisplayResult') }}
      <ws-tooltip :text="$t('TestDisplayResultTooltip')">
        <v-icon class="ml-2" :color="wsACCENT">mdi-information-outline</v-icon>
      </ws-tooltip>
    </h5>

    <ws-select
        v-model="test.display_result"
        @change="editTest($event,'display_result')"
        :items="displayTestsSelect"
        number number-length="3"
        hide-details
        width="111px"
        class="py-2"
    />

  </div>

  <!-- Test Limits -->
  <div class="px-10 d-flex align-center justify-space-between"
       style="border-bottom: 1px solid var(--wsBACKGROUND)"
  >
    <h5 class="font-weight-regular wsDARKER d-flex align-center mr-5">
      {{ $t('TestUnlimitedTooltip') }}
    </h5>


    <ws-select
        v-model="test.unlimited"
        @change="editTest($event,'unlimited')"
        :items="limitTestSelect"
        number number-length="3"
        hide-details
        width="111px"
        class="py-2"
    />

  </div>

  <!-- Number of tries -->
  <div v-if="test.unlimited"
       class="px-10 d-flex align-center justify-space-between"
       style="border-bottom: 1px solid var(--wsBACKGROUND)"
  >
    <h5 class="font-weight-regular wsDARKER d-flex align-center mr-5">
      {{ $t('Tries') }}
    </h5>

    <ws-text-field
        v-model="test.tries"
        @change="editTest($event,'tries')"
        class="py-2"
        placeholder="50"
        number
        number-length="4"
        width="111"
        hide-details
    />

  </div>

  <!-- Duration limit -->
  <div v-if="test.unlimited"
       class="px-10 d-flex align-center justify-space-between"
       style="border-bottom: 1px solid var(--wsBACKGROUND)"
  >
    <h5 class="font-weight-regular wsDARKER d-flex align-center mr-5">
      {{ $t('Duration') }}
    </h5>

    <ws-text-field
        v-model="test.duration"
        @change="editTest($event,'duration')"
        class="py-2"
        placeholder="50"
        number
        number-length="4"
        width="111"
        hide-details
    />

  </div>



  <!-- Actions Slot-->
  <portal :to="`test-navigation-slot-settings`">
    <v-icon >mdi-chevron-left</v-icon>
  </portal>

</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "testSettings",
  props : {
    test : {
      type : Object,
      default() { return {}}
    }
  },
  computed : {
    limitTestSelect() {
      return [
        { text : this.$t('No'), value : false },
        { text : this.$t('Yes'), value : true },
      ]
    },
    displayTestsSelect() {
      return [
        { text : this.$t('Never'), value : null },
        { text : this.$t('DisplayTestResultOnSuccess'), value : 'on_success' },
        { text : this.$t('DisplayTestResultAlways'), value : 'always' }
      ]
    },
  },
  methods : {
    ...mapActions('tests', [
      'ADD_QUESTION_BANK',
      'CREATE_TEST_QUESTION_SETS',
      'SAVE_TEST',
    ]),

    async editTest(value,field) {
      if ( field === 'passing_score' && value > 100 ) {
        value = 100
        this.test.passing_score = 100
      }
      if ( field === 'passing_score' && value === ''  ) {
        this.notify('value')
        value = 50
        this.test.passing_score = 50
      }
      if ( field === 'passing_score' && value < 1 ) {
        value = 1
        this.test.passing_score = 1
      }

      let data = {
        [field] : value,
        uuid : this.test.uuid
      }
      let result = await this.SAVE_TEST(data)
      if ( !result ) {
        return this.notify('Error')
      }

    },
  }
}
</script>

<style scoped>

</style>