<template>
  <div>
    <div v-if="!loading" class="pb-4">
      <wsTextEditor
          v-model="entity.description"
          :html="entity.text_description"
          @input="editTaskContent"
          :component-id="component.uuid"
      />
    </div>

    <v-sheet v-else height="90" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT" />
    </v-sheet>

  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "courseEditorVideoMeeting",
  props : {
    component : {
      type : Object,
      default() { return {} }
    }
  },
  components : {
  },
  data() {
    return {
      loading : true,
      entity : {},
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),
  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_TASK_EDIT',
      'SAVE_TASK',
    ]),

    async editTaskContent(content) {
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        description : content
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },
    async initPage() {

      if ( !this.component.task ) {
        return
      }
      this.loading = true
      let result = await this.GET_TASK_EDIT( {uuid : this.component.task.value , lang : this.selectedLang } )
      if ( !result ) {
        this.loading = false
        return
      }
      this.entity = result.task
      this.loading = false

    },

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
</style>