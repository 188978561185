<template>
  <v-hover v-slot="{hover}" >

    <v-sheet
        :color="hover || edit || editDropDownOn ? wsLIGHTCARD : ''"
        style="position : relative"
        class="wsRoundedLight mb-4   transition-ease-in-out" >

      <!-- Topic Name Row -->
      <div class="d-flex wsRoundedLight justify-space-between mt-6  align-center transition-ease-in-out">

        <v-sheet color="transparent" class="d-flex align-center"  >
          <!-- Expand Icon -->
          <v-icon
              @click="expandAction"
              style="margin-left: 18px"
              :class="expand ? 'rotate' : ''"
              class="pointer"
              :color="wsACCENT">
            mdi-menu-right
          </v-icon>
          <!-- Name Text -->
          <textarea v-model="topic.name"
                    @change="editTopicName"
                    @blur="edit=false"
                    :readonly="!edit"
                    ref="text"
                    v-autowidth="{maxWidth: '580px', minWidth: '250px', comfortZone: 0}"
                    v-autogrow
                    type="text"
                    class="grow editorStyle readOnlyCursor"
                    :placeholder="$t('EnterTopicName')"
                    style="font-size: 14px ; font-weight: 700 !important ; width: 100%;"
                    rows="1"
          />

          <!-- Edit Name Button -->
          <v-fade-transition>
            <v-icon
                @click="startEdit"
                v-ripple v-if="hover || edit || editDropDownOn"
                :color="wsACCENT"
                small
                class="ml-2">
              mdi-pencil
            </v-icon>
          </v-fade-transition>
        </v-sheet>
        <!-- Edit Actions Buttons -->
        <div class="d-flex pr-3">
<!--          <ws-icon-button icon="mdi-cog" :tooltip="$t('Delete')"   small  v-if="hover || edit || editDropDownOn" />-->
         <div @click="deleteTopic(topic.uuid)">
           <ws-icon-button  icon="mdi-delete" small v-if="hover || edit || editDropDownOn" />
         </div>
        </div>

      </div>
      <!-- Topic Content Expand -->

        <div v-if="expand">

          <v-sheet :key="'topic_content' + topic.uuid "    >

            <draggable v-model="topic.content"
                       :empty-insert-threshold="30"
                       :forceFallback="true"
                       group="lecture-section"
                       handle=".lecture-section-drag"
                       tag="div"
                       v-bind="opt"
                       @start="drag = true"
                       :key="'topic_index_drag' +1"
                       @change="editTopicContent()"
                       @end=" drag = false" >

              <div v-for="(section, i) in topic.content" :key="'topic_index' + i +1"  >


                <lectureSectionHtml
                    v-if="section.isHtml"
                    @input="editTopicContent"
                    @edit="editSectionAction($event,i)"
                    :section="section"
                    :key="'html_' + i + topic.uuid" />

                <lectureSectionFile
                    v-if="section.isFile"
                    @input="editTopicContent"
                    @edit="editSectionAction($event,i)"
                    :component-id="componentId"
                    :section="section"
                    :key="'file_' + i + topic.uuid"/>

                <lecture-section-video
                    v-if="section.isVideo"
                    @input="editTopicContent"
                    @edit="editSectionAction($event,i)"
                    :component-id="componentId"
                    :section="section"
                    :key="'video_' + i + topic.uuid"/>

                <lectureSectionImage
                    v-if="section.isImage"
                    @input="editTopicContent"
                    @edit="editSectionAction($event,i)"
                    :section="section"
                    :key="'image_' + i + topic.uuid" />

              </div>

            </draggable>

          </v-sheet>

        </div>
      <!-- Add Item Button-->
      <v-fade-transition>
      <v-sheet
          outlined
          v-if="hover || edit || editDropDownOn"
          @mouseleave="displayAddButtons = false"
          color="transparent"
          class="addButtonsStyle"
          height="36">
          <div v-if="hover || edit || editDropDownOn" style="position: relative">
            <v-divider   style="border-style: dashed; "  class="mt-4" />
            <div class="d-flex justify-center" style="margin-top: -22px">
              <v-btn @mouseenter="expandAddButton" large
                     icon :color="wsATTENTION"><v-icon large>mdi-plus-circle</v-icon> </v-btn>
              <v-expand-x-transition>
                <v-sheet v-show="displayAddButtons"  color="transparent"   >
                  <div class="d-flex" >
                    <template v-for="(item,i) in addItemsSelect" >
                      <ws-icon-button
                          @click="addItemsAction(item.value)"
                          :icon="item.icon" circle
                          :tooltip="item.text"
                          :key="'addItemsButtons' + i"/>
                    </template>
                  </div>
                </v-sheet>
              </v-expand-x-transition>

            </div>
          </div>
      </v-sheet>
      </v-fade-transition>


    </v-sheet>
  </v-hover>
</template>

<script>

import {mapActions, mapState} from "vuex";
import lectureSectionHtml
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/lectureSections/lectureSectionHtml";
import lectureSectionImage
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/lectureSections/lectureSectionImage";
import lectureSectionFile
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/lectureSections/lectureSectionFile";
import lectureSectionVideo
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/lectureSections/lectureSectionVideo";
import draggable from "vuedraggable";

export default {
  name: "courseEditorLectureTopic",
  props : {
    value : {
      type : Object
    },
    topic : {
      type : Object,
      default() { return {} }
    },
    lectureId : {
      type : String,
    },
    componentId : {
      type : String,
    },
    length : {
      type : Number,
    }
  },
  components : {
    lectureSectionHtml,
    lectureSectionFile,
    lectureSectionImage,
    lectureSectionVideo,
    draggable,
  },
  data() {
    return {

      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,

      refresh : 0,
      displayFileUpload : false,
      editorReady : false,

      displayAddButtons : false,
      expand : true,
      edit : false,
      editDropDownOn : false,
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),

    addItemsSelect() {
      return [
        { text : this.$t('Topic')  , value : 'topic'        , icon : 'mdi-format-header-1' },
        { text : this.$t('Text')   , value : 'htmlSection'  , icon : 'mdi-text' },
        { text : this.$t('Image')  , value : 'imageSection' , icon : 'mdi-folder-multiple-image' },
        { text : this.$t('Video_')  , value : 'videoSection' , icon : 'mdi-video' },
        { text : this.$t('File')   , value : 'fileSection'  , icon : 'mdi-paperclip' },
      ]
    },
    // section types
    htmlSection() {
      return {
        isHtml : true,
        content : null
        }
    },
    imageSection() {
      return {
        isImage : true,
        url : ''
      }
    },
    videoSection() {
      return {
        isVideo : true,
        isUploaded : true,
        isEmbed : false,
        file : null,
        embed : null
      }
    },
    fileSection() {
      return {
        isFile : true,
        files : [],
      }
    },

  },
  watch : {
    value() {
      if ( this.value !== this.topic ) {
        this.initContent()
      }
    },
  },
  methods : {

    ...mapActions('courses', [
        'ADD_LECTURE_TOPIC',
        'DELETE_LECTURE_TOPIC',
        'EDIT_LECTURE_TOPIC_NAME',
        'EDIT_LECTURE_TOPIC_CONTENT',
        'ADD_LECTURE_TOPIC_FILE',
        'EDIT_LECTURE_TOPIC_CONTENT'
    ]),

    addItemsAction(type) {
      if ( type === 'topic' ) {
        return this.addLectureTopic()
      }
      let newItem = JSON.parse(JSON.stringify(this[type]))

      this.topic.content.push(newItem)
      this.editTopicContent()
      this.refresh++
    },
    async addLectureTopic() {

      let data = {
        lecture_id : this.lectureId,
        lang : this.selectedLang,
        order_number : this.topic.order_number + 1
      }

      let result = await this.ADD_LECTURE_TOPIC(data)
      if ( !result ) {
        return
      }
      this.$emit('newTopic', result )
    },
    async deleteTopic(uuid) {
      if ( this.length === 1 ) {
        return this.notify(this.$t('LectureMustContainOneTopicError'))
      }
      let result = await this.DELETE_LECTURE_TOPIC(uuid)
      if ( !result ) {
        return
      }
      this.$emit('deleteTopic', result )
    },
    async editTopicName() {

      this.edit = false
      let entityData = {
        uuid : this.topic.uuid,
        lang : this.selectedLang,
        text : this.topic.name,
      }

      let data = await this.EDIT_LECTURE_TOPIC_NAME(entityData)

      if (!data) {
        return this.notify(this.$t('Error'))
      }

      this.$emit('input', this.topic )

    },
    async editTopicContent() {

      let entityData = {
        uuid : this.topic.uuid,
        content : this.topic.content,
        lang : this.selectedLang
      }

      let data = await this.EDIT_LECTURE_TOPIC_CONTENT(entityData)

      if (!data) {
        return this.notify(this.$t('Error'))
      }

      this.$emit('input', this.topic )

    },
    editSectionAction(action , index ) {

      if ( action === 'delete' ) {
        this.topic.content =  this.topic.content.filter( (el,i) => i !== index)
      }
      if ( action === 'duplicate' ) {
        let duplicatedSection = Object.assign({},this.topic.content[index])
        this.topic.content.splice(index + 1, 0 , duplicatedSection)
      }
      this.editTopicContent()

    },

    // technical
    expandAddButton() {
      this.displayAddButtons = true
    },

    startEdit() {

      this.edit = true
      this.$nextTick(() => {
        let text = this.$refs.text
        let end = text.value.length || 1
        text.setSelectionRange(end,end)
        text.focus()
      })
    },

    expandAction() {
      this.expand = !this.expand
    },
    prepareExpand() {
      if (this.topic.expand === undefined || this.topic.expand === null ) {
        this.topic.expand = true
        this.topic = Object.assign({},this.topic)
      }
    },
    getTextStyle() {
      let style = this.edit ? 'cursor : text;' : ' cursor : pointer;';

      if (!this.topic.name) {
        style += `color : ${this.wsDARKLIGHT}`
      }

      return style
    },
    textClicked() {
      if ( !this.edit ) {
        this.expandAction()
      }
    },


    initContent() {
      if ( this.value ) {
        this.topic = JSON.parse(JSON.stringify(this.value))
        if ( this.topic.content.length === 0 && !this.topic.text_content ) {
          this.topic.content.push(JSON.parse(JSON.stringify(this.htmlSection)))
        }
        if ( this.topic.content.length === 0 && this.topic.text_content ) {

          let htmlSection = JSON.parse(JSON.stringify(this.htmlSection))
          htmlSection.text = this.topic.text_content
          this.topic.content.push(htmlSection)
        }
      }
    }

  },
  mounted() {
    this.initContent()

    this.prepareExpand()
  }
}
</script>

<style scoped>

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.addButtonsStyle {
  z-index:999;
  position: absolute;
  bottom : -30px ;
  right: 0;
  left: 0 ;
  margin-left : auto;
  margin-right: auto
}

.rotate {
  transform: rotate(90deg);
}
</style>