<template>
  <div>

    <ws-text-editor
        v-model="entity.description"
        :html="entity.text_description"
        @input="editTaskContent"
        :key="component.uuid + 'after_questions'"
        :component-id="component.uuid"
    />

    <v-sheet height="40"></v-sheet>

    <div v-for="(tab,i) in navigationSelect" :key="i">
      <div @click="expandAction(i)"
           class="px-5  pointer d-flex align-center justify-space-between"
           style="border-top : 1px solid var(--wsBACKGROUND); height: 50px"
      >
        <h5 class="wsDARKER d-flex align-center">
          <v-icon :color="wsACCENT">mdi-menu-{{ !tab.expanded ? 'right' : 'down' }}</v-icon>
          {{ $t(tab.text) }}
        </h5>

        <div v-if="tab.value === 'questions' && test && test.question_sets && test.question_sets.length > 0">
          <h5 @click.stop="displayPreview = true" class="wsATTENTION">{{ $t('Preview') }}</h5>
        </div>
      </div>

      <v-expand-transition>
        <div v-if="tab.expanded"
             style="border-top: 1px solid var(--wsBACKGROUND)"
        >

          <test-questions-table
              v-if="tab.value === 'questions'"
              :test="test"
              :course-id="component.course_id"
              :component="component"
              @update="initPage"
              @drag="handleDrag"
          />
          <test-settings
              v-if="tab.value === 'settings'"
              :test="test"
              :course-id="component.course_id"
          />

        </div>
      </v-expand-transition>


      <test-preview-dialog
          v-if="displayPreview"
          v-model="displayPreview"
          :test-id="test.uuid"
      />


    </div>
  </div>
</template>


<script>
import {mapActions, mapState} from "vuex";
import testQuestionsTable
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/test/testQuestionsTable.vue";
import testSettings from "@/components/pages/westudy/admin/course/coureseEditor/compenents/test/testSettings.vue";
import testPreviewDialog
  from "@/components/pages/westudy/tests/testPreviewDialog.vue";

export default {
  name: "courseEditorTest",
  components : {
    testPreviewDialog,
    testQuestionsTable,
    testSettings
  },
  props : {
    component : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      displayPreview : false,
      entity : {},
      navigationSelect : [
          { text : 'ChooseQuestions' , value : 'questions' , expanded : false} ,
          { text : 'Settings' , value : 'settings', expanded : false}
      ],
      test : {
        question_sets : []
      },
      questionBanks : [],
      entityData : {},
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),
  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_TASK_EDIT',
      'SAVE_TASK',
    ]),
    ...mapActions('tests', [
      'ADD_QUESTION_BANK',
      'CREATE_TEST_QUESTION_SETS',
      'SAVE_TEST',
      'REORDER_TEST',
    ]),

    handleDrag($event) {
      let order =  $event.map(el => el.uuid)
      let data = {
        test : this.test.uuid,
        question_sets : order,
      }
      this.REORDER_TEST(data)

      this.test.question_sets = this.COPY($event)
      this.test = this.COPY(this.test)

    },

    async editTaskContent(content) {
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        description : content
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },

    expandAction(index) {
      this.navigationSelect[index].expanded = !this.navigationSelect[index].expanded
      this.navigationSelect = [...this.navigationSelect]
    },

    async initPage() {

      if ( !this.component.task ) {
        return
      }
      this.loading = true
      let result = await this.GET_TASK_EDIT( {uuid : this.component.task.value , lang : this.selectedLang } )
      if ( !result ) {
        this.loading = false
        return
      }
      this.loading = false

      this.entity = result.task
      this.test = result.test
      this.questionBanks = result.question_banks
      if ( this.test.question_sets.length > 0 ) {
        this.$emit('testReady')
      }

    },

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>