<template>
  <div class="pl-7 pr-8 py-6">

    <ws-storage-file-picker
        v-if="!component.file_lang"
        @input="addFile"
        block
        class="mb-6"
    />

    <div v-if="component.file_lang" >

      <v-hover v-slot="{hover}">
        <div>
          <v-btn
              @click="openFile"
              :color="wsACCENT"
              class="noCaps px-0 pr-2"
              text>
            <v-icon class="mr-3" :color="wsACCENT">mdi-paperclip</v-icon>
            {{ component.file_lang.name }}
          </v-btn>
          <v-btn @click="deleteFile" icon :color="wsACCENT" v-if="hover">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>

      </v-hover>


    </div>
    <ws-file-uploader
        v-else
        :lang="lang"
        @success="addFile"   />
  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "courseEditorFile",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    lang : {
      type : String,
      default : ''
    },
    component : {
      type : Object,
      default() { return {} }
    }
  },
  watch : {
    value() {
      if ( JSON.parse(JSON.stringify(this.component)) !== JSON.parse(JSON.stringify(this.value))  ) {
        this.component = JSON.parse(JSON.stringify(this.value))
      }
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),
  },
  methods : {

    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),
    ...mapActions('courses', [ 'EDIT_COMPONENT' ]),

    async addFile(file) {

      let entityData = {
        uuid : this.component.uuid,
        lang : this.selectedLang,
        file : file.uuid,
      }

      let result = await this.EDIT_COMPONENT(entityData)
      if (!result) {
        return this.notify(this.$t('Error'))
      }

      this.component.file_lang = file
      this.$emit('input', this.component )
    },
    deleteFile() {
      this.component.file_lang = null
      this.$emit('input', this.component )
    },
    async openFile() {
      let result = await this.GET_PRIVATE_FILE(this.component.file_lang.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = this.component.file_lang.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }

  },
  mounted() {
    if ( this.value.uuid ) {
      this.component = this.value
    }
  }
}
</script>

<style scoped>

</style>