<template>
  <v-hover v-slot="{hover}">
      <div class="pt-4"  >

        <v-row  no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

          <!-- Drag Icon-->
          <v-col style=" height: 100% !important;" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <v-sheet color="transparent" width="32" class="lecture-section-drag">
              <v-icon v-if="hover"  style="cursor: grab"  :color="wsACCENT">
                mdi-drag-vertical
              </v-icon>
            </v-sheet>
          </v-col>
          <!-- Section Content -->
          <v-col style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">

            <div v-if="displayEdit">
              <!-- Video Type Selector  -->
              <v-btn-toggle @change="changeVideoType" v-model="videoType" mandatory  :color="wsACCENT" background-color="white">
                <v-btn value="upload" :style="`border-color: ${wsACCENT} !important`" color="white" class="noCaps px-6" >
                  <v-icon class="mr-2" text :color="wsACCENT">mdi-upload</v-icon>
                  {{  $t('Upload')  }}
                </v-btn>
                <v-btn value="embed" :style="`border-color: ${wsACCENT} !important`" text class="noCaps px-6" >
                  <v-icon class="mr-2" :color="wsACCENT">mdi-link-variant</v-icon>
                  {{  $t('EmbedVideo')  }}
                </v-btn>
              </v-btn-toggle>
              <!-- Upload Video Edit  -->
              <ws-video-editor
                  v-if="section.isUploaded"
                  @cancel="displayEdit = false"
                  @input="uploadVideo($event)"
                  :value="section.file"
                  :lang="lang"
              />


              <!-- Embed Video Edit  -->
              <wsEmbedEditor
                  @input="saveEmbed"
                  @cancel="displayEdit = false"
                  closable
                  v-if="section.isEmbed"
                  v-model="section.embed"
                  video />

            </div>

            <div v-else>

              <wsVideo
                  v-if="section.isUploaded"
                  :file="section.file"
              />

              <wsEmbed
                  v-if="section.isEmbed"
                  :src="section.embed.url" />
            </div>





          </v-col>
          <!-- Section Edit Buttons -->
          <v-col style=" height: 100% !important;" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <v-sheet width="32" color="transparent" class="pl-1 fill-height " >
              <ft-select
                  @input="editAction"
                  :items="editActionsSelect">
                <ws-icon-button v-if="hover" small   />
              </ft-select>
            </v-sheet>
          </v-col>

        </v-row>

      </div>
  </v-hover>

</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "lectureSectionVideo",
  props : {
    value : {
      type : Object
    },
    section : {
      type : Object,
      default() { return {} }
    },
    lang : {
      type : String,
      default : 'ua',
    },
    componentId : {
      type : String
    }
  },
  data() {
    return {
      videoType : 'upload',
      displayEdit : true,
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),

    youtubeValidation() {

      let regex = /^http(?:s?):\/\/(?:www\.|web\.|m\.)?facebook\.com\/([A-z0-9.]+)\/videos(?:\/[0-9A-z].+)?\/(\d+)(?:.+)?$/gm;

      if ( regex.test(this.section.url) ) {
        return true
      }

      return false;
    },
    editActionsSelect() {
      let items =  [
        { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ];

      if ( !this.displayEdit ) {
        items.unshift({ text : this.$t('Edit') , value: 'edit' , icon : 'mdi-pencil' })
      }

      return items
    },
  },
  methods : {
    ...mapActions('courses',['EDIT_COMPONENT']),

    cancelEdit() {
    },
    editAction(action) {
      if ( action === 'edit' ) {
        this.displayEdit = true
        return;
      }

      this.$emit('edit',action)
    },
    changeVideoType(type) {
      this.section.isUploaded = type === 'upload'
      this.section.isEmbed = type === 'embed'
      this.$emit('input', this.section)
    },
    saveEmbed() {
      this.section.isEmbed = true
      this.displayEdit = false
      this.$emit('input', this.section)
    },
    async uploadVideo($event) {
      this.section.file = $event
      this.section.isUploaded = true

      let result = await this.addComponentFile($event)
      if ( !result ) {
        return
      }

      this.displayEdit = false
      this.$emit('input', this.section)
    },
    async addComponentFile(file) {
      let entityData = {
        uuid : this.componentId,
        lang : this.selectedLang,
        file : file.uuid,
        editor_content : true,
      }

      let result = await this.EDIT_COMPONENT(entityData)
      if (!result) {
        this.notify(this.$t('Error'))
        return false
      }
      return true
    },
  },
  mounted() {

    if (this.section.isVideo) {

      if ( this.section.isUploaded && this.section.file ) {
        this.displayEdit = false
      }
      if ( this.section.isEmbed && this.section.embed ) {
        this.displayEdit = false
      }
    }
  }
}
</script>

<style scoped>

</style>