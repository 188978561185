<template>
  <div>

    <div v-if="!loading">
      <wsTextEditor
          v-model="entity.description"
          :html="entity.text_description"
          @input="editTaskContent"
          :component-id="component.uuid"
      />

      <v-divider class="mt-4" />
      <div class="px-9 d-flex">

          <v-switch v-model="entity.student_reply" @change="editTaskReply"   dense inset class="mr-4"  color="green"   >
            <template #label>
              <h5>{{ $t('TaskTextUpload') }}</h5>
              <ws-tooltip :text="$t('TaskTextUploadTooltip')">
                <v-icon class="ml-2 mr-2"  :color="wsACCENT">mdi-information-outline</v-icon>
              </ws-tooltip>
            </template>
          </v-switch>
          <v-switch dense inset @change="editTaskSelfControll" v-model="entity.student_self_control" color="green"   >
            <template #label>
              <h5 class="mr-2">{{ $t('TaskSelfControl') }}</h5>
              <ws-tooltip :text="$t('TaskSelfControlTooltip')">
                <v-icon  :color="wsACCENT">mdi-information-outline</v-icon>
              </ws-tooltip>
            </template>
          </v-switch>

      </div>

      <div
          v-if="!entity.student_self_control"
          class="pl-9 pb-3 d-flex align-center">

        <v-switch v-model="entity.is_evaluated" @change="editTaskEvaluation"   dense inset class="mr-4"  color="green"   >
          <template #label>
            <h5>{{ $t('TaskRequireEvaluation') }}</h5>
            <ws-tooltip :text="$t('TaskRequireEvaluationTooltip')">
              <v-icon class="ml-2 mr-2"  :color="wsACCENT">mdi-information-outline</v-icon>
            </ws-tooltip>
          </template>
        </v-switch>

        <v-expand-x-transition>
          <div class=" pl-3 "   v-if="entity.is_evaluated">
            <ws-text-field
                @change="editTaskPassPoints"
                v-model="entity.pass_points"
                width="230"
                number
                number-length="3"
            >
              <template #prepend-inner>
                <h4 class="pt-1 mr-2 text-no-wrap">{{ $t('TaskPassPoints') }}:</h4>
              </template>
            </ws-text-field>
          </div>
        </v-expand-x-transition>

      </div>

      <v-sheet

          :style="`border : 1px solid ${wsBACKGROUND} !important`"
          :color="wsLIGHTCARD"
          class="d-flex align-center pa-3 ml-5 mr-5 my-5 wsRoundedHalf"
      >
        <v-icon :color="wsACCENT" class="mr-2">mdi-information-outline</v-icon>
        <h5 v-if="entity.student_self_control && entity.student_reply" class="font-weight-regular">
          {{ $t('TaskSelfControlUploadDescription') }}
        </h5>
        <h5 v-else-if="entity.student_self_control && !entity.student_reply" class="font-weight-regular">
          {{ $t('TaskSelfControlDescription') }}
        </h5>
        <h5 v-else class="font-weight-regular">
          {{ $t('TaskEvaluationDescription') }}
        </h5>
      </v-sheet>


    </div>

    <v-sheet v-else height="90" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT" />
    </v-sheet>

  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "courseEditorTask",
  props : {
    component : {
      type : Object,
      default() { return {} }
    }
  },
  components : {
  },
  data() {
    return {
      loading : true,
      entity : {},
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),
  },
  watch : {
    selectedLang() {
      this.initPage()
    },
    'entity.is_evaluated'(value) {
      if ( value && !this.entity.pass_points) {
        this.entity.pass_points = 50
      }
    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_TASK_EDIT',
      'SAVE_TASK',
    ]),

    async editTaskContent(content) {
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        description : content
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },
    async editTaskSelfControll(value) {
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        student_self_control : value
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },
    async editTaskReply(value) {
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        student_reply : value
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },
    async editTaskEvaluation(value) {
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        is_evaluated : value
      }
      if ( value ) {
        entityData.pass_points = this.entity.pass_points
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },
    async editTaskPassPoints(value) {
      if ( value < 0 ) {
        value = 50
        this.entity.pass_points = 50
      }
      if ( value > 100 ) {

        value = 100
        this.entity.pass_points = 100
        this.entity = Object.assign({} , this.entity)

      }
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        pass_points : value
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },
    async initPage() {

      if ( !this.component.task ) {
        return
      }
      this.loading = true
      let result = await this.GET_TASK_EDIT( {uuid : this.component.task.value , lang : this.selectedLang } )
      if ( !result ) {
        this.loading = false
        return
      }
      this.loading = false
      this.entity = result.task

    },

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
</style>