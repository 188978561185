<template>
  <ws-dialog
      v-model="display"
      :title="$t('Автоматично вибрати запитання з банку')"
      @save="saveAction"
      :disable-save="disableSaveCondition"
      :loading="LOADING"
      :hide-buttons="!questionBanksSelect.length"
  >

    <div v-if="!questionBanksSelect.length && !LOADING" class="fill-height align-center justify-center">
      <div class="pa-16">
        <div class="d-flex justify-center">
          <v-icon size="80" :color="wsACCENT">mdi-tooltip-question</v-icon>
        </div>

        <h2 class="wsDARKER text-center mt-2">У вас ще не має банків запитань</h2>
        <h4 class="wsDARKER text-center mt-2 font-weight-regular">Створюйте колекції запитань в розділі Банк запитант</h4>
        <div class="d-flex justify-center mt-4">
          <ws-button label="Створити банк запитань" class="mt-2"
                     :to="businessDashLink(`courses/${courseId}/question_banks`)"></ws-button>
        </div>

      </div>
    </div>

    <div v-else>
      <ws-select
          v-model="entityData.question_bank"
          :items="questionBanksSelect"
          label-lang="QuestionBank"
          autocomplete
      >
        <template #item="{item}">
          <h5 class="wsACCENT">{{ item.text }} ({{item.count}}) - <span class="font-weight-regular">{{ item.course_name }}</span></h5>
        </template>
      </ws-select>
      <ws-select
          class="mt-5"
          v-model="entityData.questions_quantity"
          :items="quantitySelect"
          label-lang="Кількість питань"
          autocomplete
      >

      </ws-select>

    </div>




    <!-- CONTENT-->

  </ws-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "testQuestionsSelector",
  props : {
    value : {
      type : Boolean
    },
  },
  data() {
    return {
      display : false,
      entityData : {},
      navigation : 'auto',
      navigationSelect : [
        { text : 'testing.questions.auto' , value : 'auto' },
        { text : 'testing.questions.manual' , value : 'manual' }
      ],
      questionBanks : [],
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang','course']),

    disableSaveCondition() {
      if (!this.questionBanksSelect.length) {
        return true
      }
      if (!this.entityData.question_bank || !this.entityData.questions_quantity ) {
        return true
      }

      return false
    },
    questionBanksSelect () {
      let banks = []
      this.questionBanks.forEach(el => {
        banks.push({
          text : el.name,
          value : el.uuid,
          course_id : el.course_id,
          course_name : el.course_name,
          count : el.questions_count
        })
      })
      return banks
    },
    quantitySelect() {
      if (!this.entityData.question_bank) {
        return []
      }
      let bank = this.questionBanks.find(el => el.uuid === this.entityData.question_bank)

      if (!bank ) {
        return []
      }
      const length = bank.questions_count
      let items = []

      for (let i = 1; i<=length; i++) {
        items.push({text : i , value : i})
      }

      return items
    },
    selectedBank() {
      return this.entityData.question_bank
    }
  },
  watch : {
    selectedBank() {
      this.entityData.questions_quantity = null
      this.entityData = this.COPY(this.entityData)
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('tests',[
      'GET_COURSE_QUESTION_BANKS',
    ]),

    saveAction(){
      this.$emit('save', this.entityData)
    },
    async getQuestionBanks() {
      let result = await this.GET_COURSE_QUESTION_BANKS({ uuid : 'all', lang : this.selectedLang } )
      if ( !result || result === true ) {
        return
      }
      this.questionBanks = result
    }
  },
  mounted() {
    this.getQuestionBanks()
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>