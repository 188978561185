<template>
  <div>

    <div v-if="!loading">
      <template v-if="!entity.is_new">
        <courseEditorLectureTopic
            v-for="(topic,i) in entity.topics" :key="topic.uuid + i"
            :length="entity.topics.length || 0"
            @newTopic="addNewTopic"
            @deleteTopic="deleteTopic(i)"
            :component-id="component.uuid"
            v-model="entity.topics[i]"
            :lecture-id="entity.uuid"/>
      </template>
      <div class="px-4" v-else>
        <code>entity.content : {{ entity.content }}</code>
        <ws-tip-tap
            v-model="entity.content"
            @change="$emit('input', section)"
            lecture
            to-json
        />
      </div>

    </div>

    <v-sheet v-else height="90" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT" />
    </v-sheet>

    <div v-if="entity.files.length > 0" class="pb-6 pl-3">
      <h4 :style="`color : ${wsACCENT}`" class="pl-5 mb-3"> {{ $t('OldFiles') }}:</h4>
      <v-sheet color="transparent" class="d-flex flex-wrap">

          <ft-select
              :value="null"
              v-for="(file,i) in entity.files "
              @input="oldFilesAction($event , file , i)"
              :items="[{text : $t('Open') , value : 'open'} , {text : $t('Delete') , value : 'delete'}  ]"
              :key="i + 'reply_file'"
              d-flex
          >
            <v-btn
                :color="wsACCENT"
                class="noCaps ml-3 px-1 pr-2"
                text
            ><v-icon class="mr-3">mdi-paperclip</v-icon>
              {{ SHORTEN_FILE_NAME(file.name) }}
            </v-btn>

          </ft-select>
      </v-sheet>


    </div>


  </div>
</template>

<script>

import courseEditorLectureTopic
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/lectureSections/courseEditorLectureTopic";

import {mapActions, mapState} from "vuex";
import wsTipTap from "@/components/UI/tiptap/wsTipTap.vue";

export default {
  name: "lecturesEditor",
  props : {
    component : {
      type : Object,
      default() { return {} }
    }
  },
  components : {
    wsTipTap,
    courseEditorLectureTopic,
  },
  data() {
    return {
      loading : true,
      entity : {
        name : '',
        topics : [],
        files : []
      },

      // dialogs
      displayDeleteTopic : false,
      displayUploadFile  : false,

    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),
  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_LECTURE_EDIT',
        'SAVE_LECTURE',
        'REMOVE_LECTURE_FILE'
    ]),
    ...mapActions('upload', [
      'GET_PRIVATE_FILE'
    ]),

    oldFilesAction(action , file , index) {
      if ( action === 'open' ) {
        this.openFile(file)
      }
      if ( action === 'delete' ) {
        this.deleteFile(file.uuid , index)
      }
    },

    async openFile(file) {
      let result = await this.GET_PRIVATE_FILE(file.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    deleteFile(uuid, index) {
      this.loading = true
      let data = {
        lecture: this.entity.uuid,
        file : uuid
      }
      this.REMOVE_LECTURE_FILE(data).then((out)=>{
        if ( out.result ) {
          this.entity.files.splice(index,1)
          this.notify(this.$t('FileDeleted'))
        }
        this.loading = false
      }).catch(()=> {
        this.loading = false
      })
    },
    addNewTopic(topic) {
      this.entity.topics.forEach(item => {
        if ( item.order_number >= topic.order_number ) {
          item.order_number++;
        }
      })
      this.entity.topics.splice(topic.order_number-1 , 0 , topic)
    },
    deleteTopic(index) {
      this.entity.topics.splice(index,1)
      this.notify(this.$t('TopicDeleted'))
    },

    async initPage() {

      if ( !this.component.lecture ) {
        return
      }
      this.loading = true
      let result = await this.GET_LECTURE_EDIT( {uuid : this.component.lecture.value , lang : this.selectedLang } )
      if ( !result ) {
        this.loading = false
        return
      }
      this.loading = false
      this.entity = result.lecture

    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
</style>