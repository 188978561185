<template>
  <v-hover v-slot="{hover}" >
    <v-sheet

        :color="hover || edit || editDropDownOn ? wsLIGHTCARD : ''"
        style="position : relative"
        ref="mainElement"
        class="d-flex wsRoundedLight justify-space-between py-3 transition-ease-in-out" >

      <v-sheet color="transparent" class="d-flex "  >
        <v-icon
            @click="textClicked"
            :class="value ? 'rotate' : ''"
            class="pointer"
            :color="wsACCENT">
          mdi-menu-right
        </v-icon>
        <h5  v-if="!edit"
               @click="textClicked"
               class="pointer"
               :style="`color : ${wsACCENT}`"
               style="font-size: 19px ; font-weight: 700 !important ; width: 100%;">
            {{ module.name}}
            <v-fade-transition>
              <v-icon
                  @click.stop="startEdit"
                  v-ripple v-if="hover || edit || editDropDownOn"
                  :color="wsACCENT">
                mdi-pencil
              </v-icon>
            </v-fade-transition>
          </h5>
        <textarea
              v-else
              v-model="module.name"
              @change="editModule"
              @blur="edit=false"
              @keydown.enter="editModule"
              :readonly="!edit"
              ref="text"
              v-autowidth="{maxWidth: '630px', minWidth: '250px', comfortZone: 0}"
              v-autogrow
              type="text"
              class="text-decoration-underline editorStyle "
              :placeholder="$t('EnterModuleName')"
              style="font-size: 19px ; font-weight: 700 !important ; width: 100%;"
              rows="1"
          />



      </v-sheet>

      <v-sheet v-if="hover || edit || editDropDownOn"   color="transparent" class="d-flex pr-2">
        <ws-icon-button v-if="module.block > 1" @click="$emit('reorder' , -1)" icon="mdi-chevron-up"  class=""   />
        <ws-icon-button v-if="module.block < modulesLength" @click="$emit('reorder' , 1)" icon="mdi-chevron-down"  class=" "   />
        <ft-select
            @input="editAction"
            @expand="editDropDownOn = $event"
            :items="editIcons"
            left-icons left  >
          <ws-icon-button  class="mr-4"   />
        </ft-select>

      </v-sheet>

      <!-- Add Item Button-->
      <div v-if=" (hover || edit || editDropDownOn) && (!expand || module.topics.length === 0)"
           style="z-index:999; position: absolute; bottom : -30px ; right: 0; left: 0 ; margin-left : auto; margin-right: auto">

        <div style="position: relative">
          <v-divider  style="border-style: dashed ;" ></v-divider>
          <div class="d-flex justify-center">
            <ft-select
                @input="addItem($event)"
                :icon-color="wsATTENTION"
                @expand="editDropDownOn = $event"
                max-height="700"
                colorBlack
                :items="addItems">

              <v-btn large style="margin-top: -26px"
                     icon :color="wsATTENTION"><v-icon large>mdi-plus-circle</v-icon> </v-btn>
            </ft-select>
          </div>
        </div>

      </div>

    </v-sheet>
  </v-hover>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "courseEditorModule",
  props : {
    value : {
      type : Boolean,
      default : true,
    },
    module : {
      type : Object,
      default() { return {} }
    },
    index : {
      type : Number,
    },
    modulesLength : {
      type : Number,
      default : 0
    },
    newCourse : {
      type : Boolean,
      default : false,
    }
  },
  data() {
    return {
      expand : true,
      edit : false,
      editDropDownOn : false,
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),

    addItems() {
      let items = [
        { text : this.$t('Module') , value: 'module' , icon : 'mdi-book-variant' },
        { text : this.$t('Topic') , value: 'topic' , icon : 'mdi-book-variant' },
      ];
      return items;
    },
    editIcons() {
      return [
        // { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ]
    },
    textStyle() {
      let style = this.edit ? 'cursor : text;' : ' cursor : pointer;';
      if (!this.module.name) {
        style += `color : ${this.wsDARKLIGHT}`
      } else {
        style += `color : ${this.wsACCENT} !important;`
      }
      return style
    },

  },
  watch : {
    value () {
      if ( this.value !== this.expand ) {
        this.expand = this.value
      }
    },
    expand () {
      if ( this.value !== this.expand ) {
        this.$emit('expand', this.expand )
      }
    },
    module(value) {
      if ( value !== this.module ) {
        this.prepareExpand()
      }
    }
  },
  methods : {
    ...mapActions('courses',[
        'ADD_MODULE',
        'EDIT_MODULE',
        'DELETE_MODULE',
        'ADD_TOPIC'
    ]),

    addItem(type) {

      if ( type === 'module') {
        this.addModule()
      }

      if ( type === 'topic') {
        if ( !this.expand ) {
          this.expandAction()
        }
        this.addTopic()
      }
    },
    editAction(action) {
      let result = {
        delete : () =>   this.deleteModule()
      }
      result[action]()
    },

    async addModule() {

      let data = {
        course_id : this.module.course_id,
        lang : this.selectedLang,
        block : this.module.block + 1
      }
      let result = await this.ADD_MODULE( data )
      if ( !result ) {
        return
      }
      this.$emit('update' , { entity : 'module' , data : result } )
    },
    async editModule() {
      this.edit = false
      let entityData = {
        uuid : this.module.uuid,
        name : {
          [this.selectedLang] : this.module.name
        }
      }

      let data = await this.EDIT_MODULE(entityData)

      if (!data) {
        return this.notify(this.$t('Error'))
      }

    },
    async deleteModule() {

      if ( this.modulesLength < 2 ) {
        return this.notify(this.$t('CourseMustContainAtLeastOneModule'))
      }
      if ( !await this.DELETE_MODULE(this.module.uuid) ) {
        return this.notify(this.$t('EntityCannotBeDeleted'))
      }

      let data = {
        entity : 'module' ,
        moduleIndex : this.index
      }

      this.$emit('delete' , data )
      this.notify( this.$t('ModuleDeleted') )

    },
    async addTopic() {

      let data = {
        module_id : this.module.uuid,
        lang : this.selectedLang,
        order_number : this.module.topics.length + 1
      }
      let result = await this.ADD_TOPIC(data)
      if ( !result ) {
        return
      }

      this.$emit('update' , { entity : 'topic' , data : result } )
    },

    // Technical
    startEdit() {

      this.edit = true
      this.$nextTick(() => {
        let text = this.$refs.text
        let end = text.value.length || 1
        text.setSelectionRange(end,end)
        text.focus()
      })
    },


    textClicked() {
      if ( !this.edit ) {
        this.expandAction()
      }
    },
    expandAction() {
      this.expand = !this.expand
    },
    prepareExpand() {
      if (this.module.expand === undefined || this.module.expand === null ) {
        this.module.expand = true
        this.module = Object.assign({},this.module)
      }
    },

  },
  mounted() {
    this.prepareExpand()
  }
}
</script>

<style scoped>

.editorStyle:read-only {
 color : #567186
}

.rotate {
  transform: rotate(90deg);
}

.text[placeholder]:empty:before {
  content: attr(placeholder);
}

[contenteditable=true]:empty:not(:focus):before{
  content:attr(data-ph);
}
</style>