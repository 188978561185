<template>
  <ws-dialog
      v-model="display"
      :title="$t('Title')"
      blank
      no-padding
      width="1024"
      hide-buttons
      height="100%"
      max-height="700px"
      not-centered

  >

    <div class="d-flex flex-row fill-height">

      <!-- Sidebar-->
      <v-sheet
          :color="wsLIGHTCARD"
          class="fill-height overflow-y-auto"
          width="277"
          height="100%"
          min-height="100%"
          min-width="277"
      >
        <h2 style="font-size: 19px" :style="`color : ${wsDARKER}`" class="mb-4 mt-2 px-6"> {{ dialogTitle }}</h2>
        <ws-avalon-navigation-list
            @input="handleBankChange"
            :value="selectedQuestionBank"
            :items="questionBanksSelect"
            mandatory
            no-edit
        />
      </v-sheet>

      <!-- Content -->
      <div class="flex-grow-1 pa-4 fill-height">
        <div class="fill-height d-flex flex-column">

          <!-- Header -->
          <div class="d-flex justify-end">
            <v-btn @click="display = false" icon>
              <v-icon :color="wsACCENT">mdi-close</v-icon>
            </v-btn>
          </div>

          <!-- Content -->
          <div class="flex-grow-1">
            <div v-if="!questionBanksSelect.length" class="fill-height align-center justify-center">
              <div class="pa-16">
                <div class="d-flex justify-center">
                  <v-icon size="80" :color="wsACCENT">mdi-tooltip-question</v-icon>
                </div>

                <h2 class="wsDARKER text-center mt-2">У вас ще не має банків запитань</h2>
                <h4 class="wsDARKER text-center mt-2 font-weight-regular">Створюйте колекції запитань в розділі Банк запитант</h4>
                <div class="d-flex justify-center mt-4">
                  <ws-button label="Створити банк запитань" class="mt-2"
                             :to="businessDashLink(`courses/${courseId}/question_banks`)"></ws-button>
                </div>

                </div>
            </div>

            <div v-else-if="selectedQuestionBank">
              <ws-data-table
                  :items="questionBank.questions || []"
                  :headers="questionsHeaders"
                  selectable
                  :selected-rows="selectedQuestions"
                  @select="selectedQuestions = $event.items"
              >

                <template #item.number="{item,index}">
                  <h5>{{ index + 1 }}</h5>
                </template>
                <template #item.name="{item}">
                  <h5 class="wsDARKER font-weight-regular">{{ GET_QUESTION_PREVIEW_TEXT(item.content) }}</h5>
                </template>

                <template #item.type="{item}">
                  <h5 class="wsDARKER font-weight-regular">{{ GET_QUESTION_TYPE_NAME(item.type)    }} </h5>
                </template>

              </ws-data-table>
            </div>

            <div v-else class="fill-height align-center justify-center">
              <div class="pa-16">
                <div class="d-flex justify-center">
                  <v-icon size="80" :color="wsACCENT">mdi-tooltip-question</v-icon>
                </div>

                <h2 class="wsDARKER text-center mt-2">Оберіть банк запитань</h2>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="d-flex align-center justify-end">

            <h5 class="font-weight-regular wsDARKER mr-6">
              {{ $t('Selected') }} : {{ selectedQuestions.length }}
            </h5>

            <ws-button @click="display = false" outlined label="Cancel" class="mr-2" />
            <ws-button @click="saveAction" :disabled="!selectedQuestions.length" :loading="LOADING" label="Choose"      />

          </div>



        </div>





      </div>
    </div>

    <template #footer>
      <div/>
    </template>

    <!-- CONTENT-->

  </ws-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import wsAvalonNavigationList from "@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList.vue";
import tests from "@/mixins/tests";

export default {
  name: "testQuestionsSelector",
  mixins : [tests],
  components: {wsAvalonNavigationList},
  props : {
    value : {
      type : Boolean
    },
    courseId : {
      type : String,
    }
  },
  data() {
    return {
      display : false,
      entityData : {},
      navigation : 'auto',
      navigationSelect : [
        { text : 'testing.questions.auto' , value : 'auto' },
        { text : 'testing.questions.manual' , value : 'manual' }
      ],
      questionBanks : [],
      selectedQuestionBank : null,
      selectedQuestions : [],
      questionBank : {}
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang','course']),

    questionsHeaders() {
      return [
        { text : '#' , value : 'number' , sortable : false , width : '10'},
        { text : this.$t('Question') , value : 'name' },
        { text : this.$t('Type') , value : 'type' , width: 184 }
      ]
    },
    questionBanksSelect () {
      let items = this.COPY(this.questionBanks)
      let coursesFolders = items.reduce((acc, item) => {
        if (item.course_id) {
          const existingCourse = acc.find(course => course.value === (item.course_id + 'course'));

          if (existingCourse) {
            existingCourse.children.push({
              text: item.name,
              value: item.uuid,
              no_edit : true
            });
          } else {
            acc.push({
              text: item.course_name,
              value: item.course_id + 'course',
              expanded : item.course_id === this.courseId ,
              is_folder : true,
              children: [{
                text: item.name,
                value: item.uuid,
                no_edit : true
              }]
            });
          }
        }
        return acc;
      }, []);

      coursesFolders = coursesFolders.sort((a,b) => {
        return b.expanded - a.expanded
      } )

      return coursesFolders
    },
    quantitySelect() {
      const length = 5
      let items = []

      for (let i = 1; i<=length; i++) {
        items.push({text : i , value : i})
      }

      return items
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    selectedQuestionBank() {
      this.getQuestionBank()
    }
  },
  methods : {
    ...mapActions('tests',[
      'GET_COURSE_QUESTION_BANKS',
    ]),
    ...mapActions('tests', [
      'GET_QUESTION_BANK_EDIT',
    ]),

    async getQuestionBank() {
      if (!this.selectedQuestionBank) {
        this.questionBank = {}
        return
      }
      this.loading = true
      let result = await this.GET_QUESTION_BANK_EDIT({uuid: this.selectedQuestionBank, lang: this.selectedLang})
      if (!result) {
        this.loading = false
        return
      }
      this.questionBank = result
      this.loading = false

    },

    handleBankChange(value) {
      if (!value.includes('course')) {
        this.selectedQuestionBank = value
      }
    },
    saveAction(){
      this.$emit('save', this.selectedQuestions)
    },
    async getQuestionBanks() {
      let result = await this.GET_COURSE_QUESTION_BANKS({ uuid : 'all', lang : this.selectedLang } )
      if ( !result || result === true ) {
        return
      }
      this.questionBanks = result
    }
  },
  mounted() {
    this.getQuestionBanks()
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>