<template>
  <div style="position: relative">
    <v-divider  style="border-style: dashed ;" ></v-divider>
    <div class="d-flex justify-center">
      <ft-select
          @input="$emit('addItem',$event)"
          :items="getAddItems"
          @expand="$emit('expand',$event)"
          :icon-color="wsATTENTION"
          :min-height="$store.state.courses.course.online ? '340' : '300'"
          colorBlack >
        <v-btn large style="margin-top: -22px; background-color: white"
               icon :color="wsATTENTION"><v-icon large>mdi-plus-circle</v-icon> </v-btn>
      </ft-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "courseEditorAddItems",
  computed : {
    getAddItems() {
      let items = [
        { text : this.$t('Module') , value: 'module' , icon : 'mdi-book-variant' },
        { text : this.$t('Topic')  , value: 'topic'  , icon : 'mdi-book-variant' },
        { text : this.$t('Lecture')      , value : 'lecture'    , icon : 'mdi-book-open'              },
        { text : this.$t('Assignment')   , value : 'task'       , icon : 'mdi-content-paste'          },
        { text : this.$t('Test')         , value : 'test'       , icon : 'mdi-order-bool-ascending-variant' },
        { text : this.$t('VideoMeeting') , value : 'task_video' , icon : 'mdi-cast-education'         },
        { text : this.$t('Video_')        , value : 'video'      , icon : 'mdi-video'                  },
        { text : this.$t('File')         , value : 'file'       , icon : 'mdi-file'                   },
      ];
      if ( !this.$store.state.courses.course.online ) {
        items.splice(5,1)
      }
      return items
    },
  }
}
</script>

<style scoped>

</style>