<template>
  <div >
    <div v-if="!hasQuestions" class="py-10">
      <div class="d-flex justify-center">
        <v-icon size="80" :color="wsDARKLIGHT">mdi-tooltip-question</v-icon>
      </div>

      <h3 class="text-center wsDARKER">{{ $t('Додайте запитання') }}</h3>
      <h5 class="text-center font-weight-regular wsDARKER mt-2 mx-auto" style="max-width: 458px">{{ $t('Додавайте запитання з наявних банків запитань, або створюйте нові запитання, або додавайте випадкові запитання') }}</h5>

      <div class="d-flex justify-center mt-4">
        <ft-select
            :items="addQuestionsActionsSelect"
        >
          <ws-button
              label="Додати запитання"
              left-icon="mdi-plus"
              icon="mdi-menu-down"
              :click-stop="false"
          />
        </ft-select>

      </div>

    </div>

    <div v-else>
      <ws-data-table
          :items="test.question_sets"
          :headers="headers"

          disable-pagination
          :no-footer="test.question_sets.length < 10"
          :selected-rows="selectedQuestionsSets"
          @select="selectedQuestionsSets = $event.items"
          @table-drag-end="handleDrag"
          enable-drag
          selectable
      >

        <template #item.name="{item}">

          <div v-if="item.type === 'auto'" class="d-flex align-center shorten-text">
<!--            <v-icon :color="wsATTENTION" small class="mr-2">mdi-dice-5</v-icon>-->
            <h5 class="wsATTENTION">
              {{ $t('Choose') }}
              <span v-if="item.question_set_groups">
                 {{ $tc('Questions' , item.question_set_groups[1]) }}
              </span>
               {{ $t('from') }}
              {{ item.question_bank_name }}

            </h5>
          </div>


          <div class="d-flex align-center" v-if="item.type === 'question'" >
<!--            <v-icon :color="wsACCENT" small class="mr-2">mdi-tooltip-question</v-icon>-->

            <div class="">
              <div class="shorten-text">
                <h5 class="wsACCENT">{{ GET_QUESTION_TYPE_NAME(item.question.type)}} - {{ item.question_bank_name }}</h5>
              </div>

              <div class="shorten-text">
                <h5 class="wsACCENT font-weight-regular d-flex ">
                  {{ GET_QUESTION_PREVIEW_TEXT(item.question.content ,100 ) }}
                </h5>
              </div>

            </div>


          </div>


        </template>
        <template #item.question_bank_name="{item}">
          <h5 class="wsDARKER font-weight-regular">{{ item.question_bank_name }}</h5>
        </template>

        <template #item.points="{item}">
          <div class="d-flex align-center" style="column-gap: 12px">

            <v-btn :color="wsACCENT" @click="editQuestionSetPoints(item,-1)" icon small :disabled="item.points < 2 || LOADING">
              <v-icon >mdi-minus</v-icon>
            </v-btn>

            <h4 class="font-weight-regular wsDARKER">{{ item.points }} </h4>
            <v-btn :color="wsACCENT" @click="editQuestionSetPoints(item,1)" icon small :disabled="LOADING"  >
              <v-icon >mdi-plus</v-icon>
            </v-btn>

            <v-btn @click="deleteQuestionSet(item)"  :color="wsACCENT" class="ml-2" icon small :disabled="LOADING">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>


          </div>
        </template>

      </ws-data-table>

      <div class="py-4 px-3 d-flex" style="border-top: 1px solid var(--wsBACKGROUND)">
        <ft-select
            :items="addQuestionsActionsSelect"
        >
          <h5 class="wsATTENTION d-flex align-center ">
            <v-icon :color="wsATTENTION" class="mr-2 ml-2">mdi-plus</v-icon>
            {{ $t('Додати запитання') }}
            <v-icon :color="wsATTENTION">mdi-menu-down</v-icon>
          </h5>

        </ft-select>
      </div>



    </div>

    <test-question-creator
        v-if="displayCreateDialog"
        v-model="displayCreateDialog"
        :component="component"
        @save="handleAddSelectQuestionManual"
    />
    <test-questions-selector
        v-if="displaySelectDialog"
        v-model="displaySelectDialog"
        @save="handleAddSelectQuestion"
    />

    <test-questions-selector-manual
        v-if="displaySelectManualDialog"
        v-model="displaySelectManualDialog"
        :course-id="courseId"
        @save="handleAddSelectQuestionManual"
    />

    <portal :to="`test-navigation-slot-questions`">
      <v-icon >mdi-chevron-left</v-icon>
    </portal>

  </div>
</template>

<script>
import testQuestionsSelector
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/test/testQuestionsSelector.vue";
import testQuestionsSelectorManual
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/test/testQuestionsSelectorManual.vue";
import testQuestionCreator
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/test/testQuestionCreator.vue";


import {mapActions} from "vuex";
import tests from "@/mixins/tests";

export default {
  name: "testQuestionsTable",
  mixins : [tests],
  components: {
    testQuestionsSelector,
    testQuestionsSelectorManual,
    testQuestionCreator
  },
  props : {
    test : {
      type : Object,
      default() { return {}}
    },
    courseId : {
      type : String,
    },
    component : {}
  },
  data() {
    return {
      displayCreateDialog : false,
      displaySelectDialog : false,
      displaySelectManualDialog : false,
      selectedQuestionsSets : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Question'), value :'name'  , sortable : false},
        // { text : this.$t('QuestionBank'), value :'question_bank_name'  , sortable : false ,  width : '180'},

        { text : this.$t('Points'), value :'points' , sortable : false, width : '50'  }
      ]
    },
    addQuestionsActionsSelect() {
      return [
        { text : this.$t('testing.create_question')  , icon : 'mdi-plus' , action : this.openNewQuestion},
        { text : this.$t('Вибрати автоматично довільні') , icon : 'mdi-dice-multiple' , action : this.openChooseQuestions},
        { text : this.$t('ChooseQuestions') , icon : 'mdi-checkbox-multiple-outline' , action : this.openChooseQuestionsManual}
      ]
    },
    hasQuestions() {
      return this.test.question_sets && this.test.question_sets.length > 0
    }
  },
  methods : {
    ...mapActions('tests', [
      'ADD_QUESTION_BANK',
      'CREATE_TEST_QUESTION_SETS',
      'SAVE_TEST',
      'EDIT_QUESTION_SET',
      'DELETE_QUESTION_SET',
    ]),

    async editQuestionSetPoints(item, value) {
      item.points += value
      await this.EDIT_QUESTION_SET(item)

    },
    deleteQuestionSet(item) {
      this.DELETE_QUESTION_SET(item.uuid)
      this.test.question_sets = this.test.question_sets.filter(el => el.uuid !== item.uuid)
      this.test = this.COPY(this.test)
    },

    handleDrag($event) {
      this.$emit('drag', $event)
    },
    async addAutoQuestionSet($event) {

      let data = {
        test_id : this.test.uuid,
        question_sets : [
          {
            bank_id : $event.question_bank,
            amount : $event.questions_quantity,
            points : 1
          }
        ]
      }

      let result = await this.CREATE_TEST_QUESTION_SETS(data)

      if ( !result ) {
        this.notify(this.$t('Error'))
        return false
      }
      return true
    },

    async addManualQuestionSet($event) {

      let data = {
        test_id : this.test.uuid,
        questions : $event
      }

      let result = await this.CREATE_TEST_QUESTION_SETS(data)

      if ( !result ) {
        this.notify(this.$t('Error'))
        return false
      }
      return true
    },

    async handleAddSelectQuestion($event) {
      await this.addAutoQuestionSet($event)
      this.displaySelectDialog = false
      this.$emit('update')
    },
    async handleAddSelectQuestionManual($event) {
      await this.addManualQuestionSet($event)
      this.displaySelectManualDialog = false
      this.displayCreateDialog = false
      this.$emit('update')
    },
    openNewQuestion() {
      this.displayCreateDialog = true
    },
    openChooseQuestions() {
      this.displaySelectDialog = true
    },
    openChooseQuestionsManual() {
      this.displaySelectManualDialog = true
    }
  }
}
</script>

<style scoped>

</style>